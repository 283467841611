import React from "react";
import { Typography } from "antd"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"

const { Title } = Typography;

const ContactPage = () => (
  <Layout className="contact">
    <SEO title="Contact" />

    <Title className="page-title">
      Fill out the form below or call us to get the best darn cleaning service in Denver Area!
    </Title>

    <ContactForm />

  </Layout>
)

export default ContactPage
