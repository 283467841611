import "./ContactForm.less";
import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, Select, Checkbox, Switch, Typography } from 'antd';
import ContactDetails from "../CalculatorComponents/ContactDetails"
import AdditionalInfo from "../CalculatorComponents/AdditionalInfo"

const ContactForm = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <Form
      form={form}
      name="contact-form"
      onFinish={onFinish}
      layout="vertical"
      className="contact-form-wrapper"
    >
      <Row gutter={24} justify="center">
        <Col className="contact-form" xs={22} md={{ span: 16}} lg={{ span: 16 }} xl={{ span: 12}}>
          <ContactDetails />

          <AdditionalInfo placeholder="What Can We Help You With?" rows={7}/>

          <Row gutter={24} justify="center">
            <Col xs={12} md={{ span: 6 }}>
              <Form.Item>
                <Button type="primary" className="primary-cta" htmlType="submit" style={{ width: "100%" }}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactForm;